<script setup lang="ts">
export interface BaseSocialIconsProps {
  /**
   * The URL for the Facebook link.
   */
  facebookUrl?: string;
  /**
   * The URL for the Youtube link.
   */
  youtubeUrl?: string;

  /**
 * The URL for the X link.
 */
  xUrl?: string;

  /**
 * The URL for the Instagram link.
 */
  instagramUrl?: string;
  /**
 * The URL for the TikTok link.
 */
  tikTokUrl?: string;

  /**
 * The URL for the website link.
 */
  webUrl?: string;

  /**
 * The URL for the mail link.
 */
  mailUrl?: string;

  /**
 * The URL for the Google Play link.
 */
  googlePlayUrl?: string;

  /**
 * The URL for the App Store link.
 */
  appStoreUrl?: string;
}

const props = defineProps<BaseSocialIconsProps>();
</script>

<template>
  <div class="flex justify-center gap-8">
    <a
      v-if="props.facebookUrl"
      :href="props.facebookUrl"
      target="_blank"
      aria-label="Facebook Link"
    >
      <IconSocialFacebook />
    </a>
    <a
      v-if="props.xUrl"
      :href="props.xUrl"
      target="_blank"
      aria-label="X Link"
    >
      <IconSocialX />
    </a>
    <a
      v-if="props.instagramUrl"
      :href="props.instagramUrl"
      target="_blank"
      aria-label="Instagram Link"
    >
      <IconSocialInstagram />
    </a>
    <a
      v-if="props.youtubeUrl"
      :href="props.youtubeUrl"
      target="_blank"
      aria-label="Youtube link"
    >
      <IconSocialYoutube />
    </a>
    <a
      v-if="props.tikTokUrl"
      :href="props.tikTokUrl"
      target="_blank"
      aria-label="TikTok link"
    >
      <IconSocialTikTok />
    </a>
    <a
      v-if="props.webUrl"
      :href="props.webUrl"
      target="_blank"
      aria-label="Website Link"
    >
      <IconSocialWeb />
    </a>
    <a v-if="props.mailUrl" :href="`mailto:${props.mailUrl}`" aria-label="Mail Link">
      <IconSocialMail />
    </a>
  </div>
  <div v-if="props.googlePlayUrl || props.appStoreUrl" class="flex flex-wrap items-center justify-center gap-8">
    <a
      v-if="props.googlePlayUrl"
      :href="props.googlePlayUrl"
      target="_blank"
      aria-label="Google Play"
    >
      <IconSocialGooglePlay />
    </a>
    <a
      v-if="props.appStoreUrl"
      :href="props.appStoreUrl"
      target="_blank"
      aria-label="App Store"
    >
      <IconSocialAppStore />
    </a>
  </div>
</template>
